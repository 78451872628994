// @ts-check
/**
 * List with all valid events and states from orchestrator
 * Only listed items that are used in Frontend
 * Original: https://dev.azure.com/next-data-service/13394-nextOR/_git/nextor-orchestrator?path=/src/services/events/events.js
 */

const EVENTS = {
    // User induced Events
    DiscardManualChanges: "DiscardManualChanges",
    EditManualPlan: "EditManualPlan",
    PublishSession: "PublishSession",
    CompleteManualChanges: "CompleteManualChanges", // adjusted in FE
    UndoManualChange: "UndoManualChange",

    // Plan calculation started
    PrepareRequestSchedulerPlan: "PrepareRequestSchedulerPlan", // Schedule Plan is in the process of being requested
    AutomaticRequestSchedulerPlanCritical: "AutomaticRequestSchedulerPlanCritical", // automatic request triggered by critical changes
    AutomaticRequestSchedulerPlanUncritical: "AutomaticRequestSchedulerPlanUncritical", // automatic request without critical changes
    ManualRequestSchedulerPlanUncritical: "ManualRequestSchedulerPlanUncritical", // manual request without critical changes
    AutomaticUpdateSchedulerPlanCritical: "AutomaticUpdateSchedulerPlanCritical", // automatic update triggered by critical changes
    AutomaticUpdateSchedulerPlanUncritical: "AutomaticUpdateSchedulerPlanUncritical", // auto-update unpublished schedule no critical changes
    AutomaticUpdateEditedPlanCritical: "AutomaticUpdateEditedPlanCritical",
    AutomaticUpdateEditedPlanUncritical: "AutomaticUpdateEditedPlanUncritical",

    // Plan calculation completed
    SuccessAutomaticRequestSchedulerPlanCritical: "SuccessAutomaticRequestSchedulerPlanCritical",
    SuccessAutomaticRequestSchedulerPlanUncritical: "SuccessAutomaticRequestSchedulerPlanUncritical",
    SuccessManualRequestSchedulerPlanUncritical: "SuccessManualRequestSchedulerPlanUncritical",
    SuccessAutomaticUpdateSchedulerPlanCritical: "SuccessAutomaticUpdateSchedulerPlanCritical",
    SuccessAutomaticUpdateSchedulerPlanUncritical: "SuccessAutomaticUpdateSchedulerPlanUncritical",
    SuccessAutomaticUpdateEditedPlanCritical: "SuccessAutomaticUpdateEditedPlanCritical",
    SuccessAutomaticUpdateEditedPlanUncritical: "SuccessAutomaticUpdateEditedPlanUncritical",

    // Plan calculation failed
    FailAutomaticRequestSchedulerPlanCritical: "FailAutomaticRequestSchedulerPlanCritical",
    FailAutomaticRequestSchedulerPlanUncritical: "FailAutomaticRequestSchedulerPlanUncritical",
    FailManualRequestSchedulerPlanUncritical: "FailManualRequestSchedulerPlanUncritical",
    FailAutomaticUpdateSchedulerPlanCritical: "FailAutomaticUpdateSchedulerPlanCritical",
    FailAutomaticUpdateSchedulerPlanUncritical: "FailAutomaticUpdateSchedulerPlanUncritical",
    FailAutomaticUpdateEditedPlanCritical: "FailAutomaticUpdateEditedPlanCritical",
    FailAutomaticUpdateEditedPlanUncritical: "FailAutomaticUpdateEditedPlanUncritical",

    // Critical Event
    CriticalEventRegisterEmergency: "CriticalEventRegisterEmergency",
    CriticalEventLocationUnavailable: "CriticalEventLocationUnavailable",
    CriticalEventSlotChanged: "CriticalEventSlotChanged",
    CriticalEventSurgeonUnavailable: "CriticalEventSurgeonUnavailable",
    CriticalDifferenceAppointmentMoved: "CriticalDifferenceAppointmentMoved",
    CriticalDifferenceAppointmentMissing: "CriticalDifferenceAppointmentMissing",

    // Notifications
    AvailabilityChange: "AvailabilityChange"
};

const FAIL_EVENTS = [
    EVENTS.FailAutomaticRequestSchedulerPlanCritical,
    EVENTS.FailAutomaticRequestSchedulerPlanUncritical,
    EVENTS.FailManualRequestSchedulerPlanUncritical,
    EVENTS.FailAutomaticUpdateSchedulerPlanCritical,
    EVENTS.FailAutomaticUpdateSchedulerPlanUncritical,
    EVENTS.FailAutomaticUpdateEditedPlanCritical,
    EVENTS.FailAutomaticUpdateEditedPlanUncritical
];

const STATES = {
    PendingAutomaticRequestSchedulerPlanCritical: "PendingAutomaticRequestSchedulerPlanCritical",
    PendingAutomaticRequestSchedulerPlanUncritical: "PendingAutomaticRequestSchedulerPlanUncritical",
    PendingManualRequestSchedulerPlanUncritical: "PendingManualRequestSchedulerPlanUncritical",
    PendingAutomaticUpdateSchedulerPlanCritical: "PendingAutomaticUpdateSchedulerPlanCritical",
    PendingAutomaticUpdateSchedulerPlanUncritical: "PendingAutomaticUpdateSchedulerPlanUncritical",
    PendingAutomaticUpdateEditedPlanCritical: "PendingAutomaticUpdateEditedPlanCritical",
    PendingAutomaticUpdateEditedPlanUncritical: "PendingAutomaticUpdateEditedPlanUncritical",
    EditedPlanCritical: "EditedPlanCritical", // to be deleted
    PendingSolveEditedPlanCritical: "PendingSolveEditedPlanCritical"
};

/** @type {{[key: string]: "newPlanAutomatic" | "newPlanManual" | "minorAdjust" | "newPlanUnsaved"}} */
const BLOCK_SCREEN_TYPES = {
    // PublishedPlan
    [EVENTS.AutomaticRequestSchedulerPlanCritical]: "newPlanAutomatic",
    [STATES.PendingAutomaticRequestSchedulerPlanCritical]: "newPlanAutomatic",
    [EVENTS.ManualRequestSchedulerPlanUncritical]: "newPlanManual",
    [STATES.PendingManualRequestSchedulerPlanUncritical]: "newPlanManual",
    [EVENTS.AutomaticRequestSchedulerPlanUncritical]: "minorAdjust",
    [STATES.PendingAutomaticRequestSchedulerPlanUncritical]: "minorAdjust",

    // CompletedPlan
    [EVENTS.AutomaticUpdateSchedulerPlanCritical]: "newPlanAutomatic",
    [STATES.PendingAutomaticUpdateSchedulerPlanCritical]: "newPlanAutomatic",
    [EVENTS.AutomaticUpdateSchedulerPlanUncritical]: "minorAdjust",
    [STATES.PendingAutomaticUpdateSchedulerPlanUncritical]: "minorAdjust",

    // EditedPlan
    // [STATES.EditedPlanCritical]: "newPlanUnsaved",
    [STATES.PendingSolveEditedPlanCritical]: "newPlanUnsaved",
    [STATES.PendingAutomaticUpdateEditedPlanCritical]: "newPlanUnsaved",
    [EVENTS.AutomaticUpdateEditedPlanUncritical]: "minorAdjust",
    [STATES.PendingAutomaticUpdateEditedPlanUncritical]: "minorAdjust"

    // Failure -> block screen won't be shown
};

const teaserEvents = [STATES.PendingSolveEditedPlanCritical, STATES.PendingAutomaticUpdateEditedPlanCritical];

const maxDurationBlocked = 45; // in seconds

const TEASER_TRANSLAION_KEY = {
    manualChangesToBePublished: "manualChangesToBePublished",
    manualChangesToBePublishedOpManager: "manualChangesToBePublishedOpManager"
};
const SEVERITY = {
    INFO: "info",
    ERROR: "error",
    SUCCESS: "success"
};

const NOTIFICATION_EVENTS = {
    [EVENTS.SuccessAutomaticRequestSchedulerPlanCritical]: {
        severity: SEVERITY.INFO
    },
    [EVENTS.SuccessAutomaticUpdateSchedulerPlanCritical]: {
        severity: SEVERITY.INFO
    },
    [EVENTS.AvailabilityChange]: {
        severity: SEVERITY.INFO
    }
};

const WS_EVENTS = {
    Message: "Message",
    UserSolveEvent: "UserSolveEvent",
    Refresh: "Refresh",
    ErrorEvent: "ErrorEvent",
    connect_error: "connect_error",
    Event: "Event"
};

const WS_ACTIONS = {
    Snooze: "Snooze",
    State: "State",
    UserUndoManualChange: "UserUndoManualChange",
    UserDiscardChanges: "UserDiscardChanges",
    UserCompleteChanges: "UserCompleteChanges"
};

module.exports = {
    EVENTS,
    FAIL_EVENTS,
    STATES,
    BLOCK_SCREEN_TYPES,
    teaserEvents,
    maxDurationBlocked,
    WS_EVENTS,
    WS_ACTIONS,
    NOTIFICATION_EVENTS,
    SEVERITY,
    TEASER_TRANSLAION_KEY
};
